import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Box, Button, CardContent, Chip, CircularProgress, Grid, Tooltip, Typography} from "@mui/material";
import {useParams} from "react-router";
import {useGetProductByIdQuery} from "../../core/api/products-api";
import StraightenIcon from '@mui/icons-material/Straighten';
import CreateOrderDialog from "./CreateOrderDialog";
import SizeTableDialog from "./SizeTableDialog";
import Card from "@mui/material/Card";
import {useSelector} from "react-redux";
import "./Product.css";
import ProductImageViewer from "./ProductImageViewer";
import CreateGiftDialog from "./CreateGiftDialog";
import ProductPrice from "../Common/PriceComponent";
import ResponseDialog from "../ResponseDialog";
import SaleLabel from "./SaleLabel";
import {Alert} from "@mui/lab";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import LimitedLabel from "./LimitedLabel";
import DOMPurify from "dompurify";


const CENTRAL_STORAGE = "Литва";
export default function Product(props) {
    const {productId} = useParams()
    const sessionState = useSelector((state) => state.data.UserSession);
    const userBalance = sessionState.usersDetails.balance;
    const userSelectedLocation = sessionState.usersDetails.location ? sessionState.usersDetails.location : "";
    const {
        data: response = {
            product: {
                id: "",
                title: "",
                description: "",
                price: 0,
                images: [],
                sizeTableImage: "",
                availability: [],
                variations: [],
                variationsAmount: {}
            },
            availableInLocation: false
        }, isFetching, isSuccess, refetch
    } = useGetProductByIdQuery({productId, userSelectedLocation})

    const getLocationsVariations = (storageAmount, location) => {
        if ((storageAmount === undefined || storageAmount === null || location === "")) {
            return [];
        } else {
            let storages = storageAmount.filter(item => item.location === location);
            if (storages.length === 0 || storages[0].variationsAmountMap === null) {
                return [];
            }
            let variations = [];
            const variationsMap = new Map(Object.entries(storages[0].variationsAmountMap));
            variationsMap.forEach((value, key) => {
                variations.push(key)
            })
            return variations;
        }
    }

    const isProductHasVariations = getLocationsVariations(response.product.locationStorageAmounts, userSelectedLocation).length !== 0;
    const [state, setState] = useState({
        selectedVariant: "",
        mainImageSrc: "",
        imagesSrcArray: [],
        imagesSubArray: [],
        sizeTableImage: "",
        isCreateOrderDialogOpen: false,
        isCreateGiftDialogOpen: false,
        isSizeTableOpen: false,
        isOrderButtonDisable: false,
        isProductAvailable: false,
        isCreateOrderResponseOpen: false,
        createOrderResponse: {
            response: {},
            dialogResponseInformationElement: {},
            dialogResponseInformation: ""
        },
        errors: {},
        isProductInStock: false,
        amountInStock: 0,
        variations: [],
        amountSizeMap: new Map(),
        showAmountInfo: false,
        isCharityProduct: false
    })


    useEffect(() => {
        if (isSuccess) {
            setState({
                ...state,
                sizeTableImage: response.product.sizeTableImage,
                isProductAvailable: response.availableInLocation ? response.availableInLocation : false,
                imagesSrcArray: response.product.images,
                mainImageSrc: response.product.images[0],
                imagesSubArray: getImagesSubArray(response.product.images),
                isOrderButtonDisable: response.product.isSale ? userBalance < response.product.salePrice : userBalance < response.product.price,
                amountInStock: getAmountsByLocation(response.product.locationStorageAmounts, userSelectedLocation),
                variations : getLocationsVariations(response.product.locationStorageAmounts, userSelectedLocation),
                amountSizeMap: getAmountSizeMap(response.product.variations, response.product.locationStorageAmounts, userSelectedLocation),
                showAmountInfo: showAmountInfo(response.product.variations),
                isProductInStock: isProductInStock(response.product),
                isCharityProduct: response.product.isCharity
            });
        }
    }, [response])

    const getAmountsMapByLocation = (storageAmount, location, variations) => {
        let storages = storageAmount.filter(item => item.location === location);
        let storagesCentral = storageAmount.filter(item => item.location === CENTRAL_STORAGE);

        if (location === "" || storages.length === 0) {
            let sizeAmountMap = new Map();
            variations.forEach(value => {
                sizeAmountMap.set(value, 0);
            });
            return sizeAmountMap;
        }
        var sizeAmountMap = new Map(Object.entries(storages[0].variationsAmountMap));
        if (storagesCentral[0] !== undefined) {
            var central = new Map(Object.entries(storagesCentral[0].variationsAmountMap));
            sizeAmountMap.forEach( (value, key) => {
                if (value === 0 && central.has(key)) {
                    sizeAmountMap.set(key, central.get(key))
                }
            });
        }
        return sizeAmountMap;
    }
    const getAmountSizeMap = (variations, storageAmount, location) => {
        if (variations === null || variations.length === 0) {
            return new Map();
        } else if ((storageAmount === undefined || storageAmount === null)) {
            let sizeAmountMap = new Map();
            variations.forEach(value => {
                sizeAmountMap.set(value, 0);
            });
            return sizeAmountMap;
        } else {
            return getAmountsMapByLocation(storageAmount, location, variations);
        }
    }


    const getAmountsByLocation = (storageAmount, location) => {
        if ((storageAmount === undefined || storageAmount === null)) {
            return 0;
        }
        let storages = storageAmount.filter(item => item.location === location);
        let centralStorage = storageAmount.filter(item => item.location === CENTRAL_STORAGE);

        if (location === "" || storages.length === 0) {

            return 0;
        }
        if (storages[0].amount === 0 && centralStorage[0] !== undefined) {
            return centralStorage[0].amount;
        }
        return storages[0].amount;
    }

    const showAmountInfo = (variations) => {
        if (response && response.product.isCharity) return false;
        if (variations === null) {
            return true;
        } else if (variations !== undefined && variations.length === 0) {
            return true;
        } else if (state.selectedVariant !== "") {
            return true;
        }
        return false;
    }
    const handleVariantClick = (variation) => {
        setState({
            ...state,
            selectedVariant: variation,
            showAmountInfo: true,
            isProductInStock: getSizeInStock(variation) > 0,
            errors: {
                size: false
            }
        })
    }

    const isProductInStock = (product) => {
        if (product.variations !== null && product.variations.length !== 0) {
            return getSizeInStock(state.selectedVariant) > 0;
        } else {
            return getAmountsByLocation(response.product.locationStorageAmounts, userSelectedLocation) > 0;
        }
    }

    const getSizeInStock = (variation) => {
        if (state.amountSizeMap !== undefined && state.amountSizeMap.size !== 0) {
            if (state.amountSizeMap.has(variation)) {
                return state.amountSizeMap.get(variation)
            }
        }
        return 0;
    }
    const handleClose = (createResponse) => {
        setState({
            ...state,
            isCreateOrderDialogOpen: false,
            isCreateGiftDialogOpen: false,
            isCreateOrderResponseOpen: createResponse.isResponseReady,
            createOrderResponse: createResponse.data,
        })
    };
    const handleResponseDialogClose = () => {
        refetch();
        setState({
            ...state,
            isCreateOrderResponseOpen: false,
        })
    };
    const handleCloseSizeTable = () => {
        setState({
            ...state,
            isSizeTableOpen: false
        })
    };
    const handleOrderButton = () => {
        if (response.product.variations !== null && response.product.variations.length !== 0 && state.selectedVariant.length === 0) {
            setState({
                ...state,
                errors: {
                    size: true
                }
            })
        } else {
            setState({
                ...state,
                isCreateOrderDialogOpen: true
            })
        }
    }

    const handleGiftButton = () => {
        if (response.product.variations !== null && response.product.variations.length !== 0 && state.selectedVariant.length === 0) {
            setState({
                ...state,
                errors: {
                    size: true
                }
            })
        } else {
            setState({
                ...state,
                isCreateGiftDialogOpen: true
            })
        }
    }

    const getImagesSubArray = (images) => {
        return images.filter((image, index) => {
            if (index !== 0) {
                return image
            }
        })
    }
    const handleSizeTableLinkClick = () => {
        setState({
            ...state,
            isSizeTableOpen: true
        })
    }

    return (
        <Box className="product-page">
            {
                isFetching ? <Box sx={{display: 'flex'}}>
                        <CircularProgress/>
                    </Box> :
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <Typography gutterBottom variant="h3" component="div">
                                    {response.product.title}
                                </Typography>
                                <Card>
                                    <CardContent className={"product-page-price"}>
                                        {response.product.isSale &&
                                            <SaleLabel
                                            oldPrice={response.product.price}
                                            newPrice={response.product.salePrice}
                                        />
                                        }

                                        {response.product.isLimited &&
                                            <LimitedLabel
                                                labelClassName = {"product-limited"}
                                                text={response.product.limitedText}
                                            />
                                        }
                                        <ProductImageViewer images={state.imagesSrcArray}/>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item sm={6} container xs={12}>
                                <Grid item xs direction="column" spacing={2}>

                                    <Grid item>
                                        <Card sx={{mb: 2, marginTop: '34px'}}>
                                            <CardContent>
                                                { response.product.isSale ?
                                                    <Box>
                                                        <ProductPrice
                                                            price={response.product.price}
                                                            style={"strikeout-price"}
                                                            salePrice={response.product.salePrice}
                                                        />
                                                    </Box>
                                                    :
                                                    <ProductPrice
                                                        price={response.product.price}/>
                                                }
                                                <br />

                                                <div dangerouslySetInnerHTML={{ __html: response.product.description }} />

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        {
                                            isProductHasVariations ?
                                                <Box>
                                                    <Typography vcomponent="div"
                                                                variant="h3"
                                                    >
                                                        Выберите размер
                                                    </Typography>

                                                    <Card sx={{mb: 3}}>
                                                        <CardContent>
                                                            <div className="variations">
                                                                <Box className={"product-variation"}>
                                                                    {state.variations.map((variation, index) =>
                                                                        <Chip
                                                                            className={`variation__item ${state.selectedVariant === variation ? "selected" : ""}`}
                                                                            key={`variations_chip_${index}`}
                                                                            id={`chip_${variation}`}
                                                                            variant="outlined"
                                                                            onClick={() => handleVariantClick(variation)}
                                                                            icon={<StraightenIcon
                                                                                sx={{pointerEvents: 'none'}}/>}
                                                                            label={variation}
                                                                        />)
                                                                    }
                                                                    {state.errors.size &&
                                                                        <p className="error-helper-text">
                                                                            *Необходимо выбрать размер
                                                                        </p>}
                                                                </Box>
                                                            </div>
                                                            {(state.sizeTableImage !== null && state.sizeTableImage !== "") &&
                                                                <Box sx={{mt: 2}}>
                                                                    <Button
                                                                        size="small"
                                                                        color="secondary"
                                                                        key={`size_table_chip`}
                                                                        id={`size_table_chip`}
                                                                        onClick={handleSizeTableLinkClick}
                                                                        label=""

                                                                    >

                                                                        Таблица размеров
                                                                    </Button>

                                                                    <SizeTableDialog onClose={handleCloseSizeTable}
                                                                                     open={state.isSizeTableOpen}
                                                                                     sizeTableImageSrc={state.sizeTableImage}/>
                                                                </Box>
                                                            }
                                                        </CardContent>

                                                    </Card>


                                                </Box>
                                                : null
                                        }
                                    </Grid>
                                    {state.showAmountInfo &&
                                        <Grid item sx={{mb: 2}}>
                                            {state.isProductInStock ?
                                                <Box>
                                                    <Alert
                                                        icon={<CheckCircleOutlineIcon
                                                            style={{ color: '#03907f' }}
                                                            fontSize="inherit" />}
                                                        severity="success">
                                                        {"Есть на складе " + ((state.selectedVariant !== "") ?
                                                            getSizeInStock(state.selectedVariant) :
                                                            state.amountInStock )+ " шт."}
                                                    </Alert>
                                                </Box> :
                                                <Box>
                                                    <Alert
                                                      icon={<ReportProblemOutlinedIcon
                                                            style={{ color: '#ff0000' }}
                                                            fontSize="inherit" />}
                                                        severity="warning">
                                                        Этого товара нет на складе. <br/>
                                                        Оформив заказ администратор магазина сообщит о возможных сроках реализации заказа.
                                                        Ориентировочное время ожидания оформления и доставки может составить 2-4 недели. <br/>
                                                        <strong>Важно!</strong><br/>
                                                        При заказе необходимо учитывать, что выбранный товар может быть доступен в ограниченном количестве/партии (без возможности повторного изготовления).
                                                        В таком случаи ваш заказ может быть отменён администратором, а вы получите уведомление.<br/>
                                                        *время ожидания изготовления и доставки мерча составляет 2-3 месяца (если нет ограничений в данной партии).<br/>
                                                        *заказ техники оформляется индивидуально.<br/>
                                                    </Alert>
                                                </Box>

                                            }
                                        </Grid>
                                    }
                                    <Grid item>
                                        {
                                            !state.isCharityProduct ? state.isOrderButtonDisable ?
                                                <Tooltip title="Недостаточно белкоинов">
                                                    <Button id="order-button"
                                                            variant="contained"
                                                            color="secondary"

                                                    >
                                                        Подарить
                                                    </Button>
                                                </Tooltip>   :
                                                <Button id="order-button"
                                                        variant="contained"
                                                        color="warning"
                                                        onClick={handleGiftButton}
                                                >
                                                    Подарить
                                                </Button> : null
                                        }

                                        { state.isProductAvailable === true ? state.isOrderButtonDisable ?
                                            <Tooltip title="Недостаточно белкоинов">
                                                <Button
                                                    sx={{ml: 2}}
                                                    id="order-button"
                                                        variant="contained"
                                                        color="secondary"

                                                >
                                                    Оформить
                                                </Button>
                                            </Tooltip>:
                                            <Button
                                                sx={{ml: 2}}
                                                id="order-button"
                                                variant="contained"
                                                color="warning"
                                                onClick={handleOrderButton}
                                            >
                                                {state.isProductInStock ? "Оформить" :  state.isCharityProduct ? "Пожертвовать": "Заказать"}
                                            </Button>
                                        :
                                            <Typography
                                                sx={{mt: 2}}
                                                gutterBottom
                                                variant="h3"
                                                component="div">
                                                Продукт недоступен в выбранной локации
                                            </Typography>
                                        }

                                        {
                                            <CreateOrderDialog
                                                isPresent={false}
                                                onClose={handleClose}
                                                open={state.isCreateOrderDialogOpen}
                                                product={response.product}
                                                variation={state.selectedVariant}
                                                inStock={state.isProductInStock}
                                                presents={{}}/>

                                        }

                                        {
                                            <CreateGiftDialog
                                                onClose={handleClose}
                                                open={state.isCreateGiftDialogOpen}
                                                product={response.product}
                                                variation={state.selectedVariant}
                                                inStock={state.isProductInStock}/>
                                        }

                                        { state.createOrderResponse &&
                                            <ResponseDialog
                                            onClose={handleResponseDialogClose}
                                            open={state.isCreateOrderResponseOpen}
                                            responseData={state.createOrderResponse.response ? state.createOrderResponse.response : {}}
                                            label={"Оформление заказа"}
                                            information={state.createOrderResponse.dialogResponseInformation}
                                            element={state.createOrderResponse.dialogResponseInformationElement}
                                            />
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
            }

        </Box>
    );
}

